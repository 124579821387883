const React = require('react');

const { useState, useEffect, useRef, useImperativeHandle } = React;
const { arrayOf, bool, func, shape } = require('prop-types');
const throttle = require('lodash/throttle');

const { GALLERY_MODE, ID_CONTAINER } = require('../../constants');
const { DEVICE_TYPE } = require('../../../../utils/constants');
const { isItemClip } = require('../../hooks/use-gallery');
const { trackPage, trackEvent } = require('../../../../lib/tracking');

// clips
const { CLIP_VIDEO_ID, CLIPS_VIDEO_PROVIDER } = require('../../../clip-video/constants');
const { clickVideo, isMuted, mute, pause, reset, restart, unmute } = require('../../../clip-video/Provider');
const {
  showDesktopMediaElements,
  initMouseHoverConfig,
  shoutOffHover,
  shoutOnHover,
} = require('../../../clip-multimedia/utils/multimedia-events');
const ClipVideo = require('../../../clip-video');
const { setVideoAppend } = require('../../../clip-video/utils/video-append');

const getMultimediaProps = (videoId, multimedia, isFullscreen, mouseHoverConfig, isBottomMultimedia) => ({
  multimedia,
  videoContainer: {
    current: isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
    opposite: !isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
  },
  videoId,
  isBottomMultimedia,
  mouseHoverConfig,
});

// ignore coverage for internal videojs lib events
const getClipMethodsForDesktop = ({
  multimediaClipRef,
  playerRef,
  isFullscreenRef,
  mouseHoverConfigRef,
  errorConfigRef,
  clipsConfig,
  isCurrentClips,
  showSnackbar,
  multimediaWatched,
  setMultimediaWatched,
  position,
  snap,
}) => {
  const handleTouch = (index, { className }, { close }) => {
    if (!multimediaClipRef.current.loading && className.includes('glass-screen')) {
      clickVideo(playerRef.current, CLIPS_VIDEO_PROVIDER);
    } else if (className.includes('exit-fullscreen')) {
      close();
    } else if (className.includes('unmuted')) {
      mute(playerRef.current, CLIPS_VIDEO_PROVIDER);
    } else if (className.includes('muted')) {
      unmute(playerRef.current, CLIPS_VIDEO_PROVIDER);
    }
  };

  const sendIntentionToViewTrack = () => {
    if (isCurrentClips && !multimediaWatched[position.current]) {
      const { current } = snap;
      const trackToSend = {
        ...current?.track_intention_to_view,
        melidata_event: {
          ...current?.track_intention_to_view.melidata_event,
          event_data: {
            ...current?.track_intention_to_view.melidata_event.event_data,
            gallery_mode: isFullscreenRef.current ? GALLERY_MODE.FULL_SCREEN : GALLERY_MODE.DEFAULT,
          },
        },
      };

      trackEvent(trackToSend);
      setMultimediaWatched(watchedList => {
        watchedList[position.current] = true;

        return watchedList;
      });
    }
  };

  const onSrcChanged = () => {
    pause(playerRef.current, CLIPS_VIDEO_PROVIDER);
    multimediaClipRef.current.paused = true;
  };

  const onWaiting = (player, options) => {
    const { currentVideo } = options;

    multimediaClipRef.current.loading = true;

    const multimediaProps = getMultimediaProps(
      currentVideo?.id,
      multimediaClipRef.current,
      isFullscreenRef.current,
      mouseHoverConfigRef.current,
    );
    showDesktopMediaElements(multimediaProps);

    if (multimediaClipRef.current.error) {
      const { message, deelay: delay } = clipsConfig?.snackbars?.find(({ id }) => id === 'OWN_ERROR') || {};

      if (message) {
        errorConfigRef.current.delayId = setTimeout(() => {
          showSnackbar({ message, type: 'generic', delay, called_from: 'gallery_vip_clip' });
        }, errorConfigRef.current.delay);
      }
    }
  };

  const onPlay = () => {
    sendIntentionToViewTrack();
  };

  const onPlaying = (player, options) => {
    const { currentVideo } = options;

    multimediaClipRef.current.paused = false;
    multimediaClipRef.current.loading = false;
    multimediaClipRef.current.hasStarted = true;

    const multimediaProps = getMultimediaProps(
      currentVideo?.id,
      multimediaClipRef.current,
      isFullscreenRef.current,
      mouseHoverConfigRef.current,
    );
    showDesktopMediaElements(multimediaProps);

    clearTimeout(errorConfigRef.current.delayId);
  };

  const onPause = (player, options) => {
    const { currentVideo } = options;

    multimediaClipRef.current.paused = true;
    multimediaClipRef.current.loading = false;

    const multimediaProps = getMultimediaProps(
      currentVideo?.id,
      multimediaClipRef.current,
      isFullscreenRef.current,
      mouseHoverConfigRef.current,
    );
    showDesktopMediaElements(multimediaProps);
  };

  const onVolumeChange = (player, options) => {
    const { currentVideo } = options;

    multimediaClipRef.current.muted = isMuted(player, CLIPS_VIDEO_PROVIDER);

    const multimediaProps = getMultimediaProps(
      currentVideo?.id,
      multimediaClipRef.current,
      isFullscreenRef.current,
      mouseHoverConfigRef.current,
      true,
    );
    showDesktopMediaElements(multimediaProps);
  };

  const onTimeUpdate = throttle(player => {
    if (player.currentTime() > 0) {
      multimediaClipRef.current.timeToLeave += 1;
    }
  }, 1000);

  const onError = player => {
    multimediaClipRef.current.error = true;
    player?.error(null);
  };

  const onMute = muted => {
    if (muted) {
      unmute(playerRef.current, CLIPS_VIDEO_PROVIDER);
    } else {
      mute(playerRef.current, CLIPS_VIDEO_PROVIDER);
    }
  };

  const onClickVideo = () => {
    clickVideo(playerRef.current, CLIPS_VIDEO_PROVIDER);
  };

  return {
    handleTouch,
    onSrcChanged,
    onWaiting,
    onPlay,
    onPlaying,
    onPause,
    onVolumeChange,
    onTimeUpdate,
    onError,
    onMute,
    onClickVideo,
  };
};

const useClipsEffects = ({
  clipsConfig,
  videoClipElRef,
  multimediaClipRef,
  isFullscreenRef,
  playerRef,
  snap,
  isCurrentClips,
  isFullscreen,
  isPreviousClips,
  onPause,
  position,
  mouseHoverConfigRef,
  eventHandlersRef,
}) => {
  useEffect(() => {
    if (clipsConfig?.hasClips) {
      videoClipElRef.current = document.getElementById(CLIP_VIDEO_ID);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { current, previous, prevClip } = snap;

    if (isCurrentClips) {
      multimediaClipRef.current.error = false;
      multimediaClipRef.current.hasStarted = false;
      multimediaClipRef.current.loading = false;
      multimediaClipRef.current.paused = true;
      multimediaClipRef.current.timeToLeave = 0;

      let trackToSend = current?.track_vip || current?.track_pdp;
      trackToSend = {
        ...trackToSend,
        melidata_event: {
          ...trackToSend?.melidata_event,
          event_data: {
            ...trackToSend?.melidata_event.event_data,
            gallery_mode: isFullscreen ? GALLERY_MODE.FULL_SCREEN : GALLERY_MODE.DEFAULT,
          },
        },
      };

      const trackDispatch = trackToSend?.melidata_event?.type === 'event' ? trackEvent : trackPage;
      trackDispatch(trackToSend);

      const videoContainer = {
        current: isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
        opposite: !isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
      };
      setVideoAppend(videoClipElRef.current, `${videoContainer.current}-${current?.id}`);

      const shoutOnHoverProps = {
        multimedia: multimediaClipRef.current,
        mouseHoverConfig: mouseHoverConfigRef.current,
        videoContainer,
        videoId: current.id,
      };
      shoutOnHover(eventHandlersRef.current, shoutOnHoverProps);

      if (clipsConfig?.autoplay) {
        if (!isItemClip(previous)) {
          restart(playerRef.current, CLIPS_VIDEO_PROVIDER);
        }
      }
    }

    /* istanbul ignore next */
    if (isPreviousClips) {
      let trackToSend = previous?.track_leave;
      const multimediaDataTrack = {
        time_to_leave: multimediaClipRef.current.timeToLeave,
        has_started_at_least_once: multimediaClipRef.current.hasStarted,
        is_muted:
          typeof multimediaClipRef.current.muted === 'undefined' ? 'not_started' : `${multimediaClipRef.current.muted}`,
        is_playing:
          typeof multimediaClipRef.current.paused === 'undefined'
            ? 'not_started'
            : `${!multimediaClipRef.current.paused}`,
      };

      trackToSend = {
        ...trackToSend,
        melidata_event: {
          ...trackToSend?.melidata_event,
          event_data: {
            ...trackToSend?.melidata_event.event_data,
            ...multimediaDataTrack,
            gallery_mode: isFullscreen ? GALLERY_MODE.FULL_SCREEN : GALLERY_MODE.DEFAULT,
          },
        },
      };

      trackEvent(trackToSend);

      multimediaClipRef.current.hasStarted = false;
      reset(playerRef.current, CLIPS_VIDEO_PROVIDER); // The events are not being triggered when withEvents=false in <ClipsVideo />
      onPause(playerRef.current, { currentVideo: prevClip }); // Execute just the logic of the event

      if (isFullscreen) {
        setVideoAppend(videoClipElRef.current, `${ID_CONTAINER.GALLERY_SCREEN}-${previous?.id}`);
      }

      const shoutOffHoverProps = {
        mediaContainer: isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
        mouseHoverConfig: mouseHoverConfigRef.current,
        videoId: prevClip.id,
      };
      shoutOffHover(eventHandlersRef.current, shoutOffHoverProps);

      const multimediaProps = getMultimediaProps(
        prevClip?.id,
        multimediaClipRef.current,
        isFullscreen,
        mouseHoverConfigRef.current,
      );
      showDesktopMediaElements(multimediaProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position.current, position.currentClip]);

  useEffect(() => {
    isFullscreenRef.current = isFullscreen;
    const { current } = snap;
    const clipContainer = isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN;
    if (isCurrentClips) {
      setVideoAppend(videoClipElRef.current, `${clipContainer}-${current?.id}`);

      const videoContainer = {
        current: isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
        opposite: !isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
      };
      const shoutOffHoverProps = {
        mediaContainer: videoContainer.opposite,
        mouseHoverConfig: mouseHoverConfigRef.current,
        videoId: current.id,
      };
      const shoutOnHoverProps = {
        mouseHoverConfig: mouseHoverConfigRef.current,
        multimedia: multimediaClipRef.current,
        videoContainer,
        videoId: current.id,
      };

      shoutOffHover(eventHandlersRef.current, shoutOffHoverProps);
      shoutOnHover(eventHandlersRef.current, shoutOnHoverProps);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullscreen]);
};

const getClickHandler = ({ multimediaClipRef, onClickVideo }) => () => {
  if (!multimediaClipRef.current.loading) {
    onClickVideo();
  }
};

const ClipsWorkerComponent = React.forwardRef(
  ({ clipsConfig, isCurrentClips, isPreviousClips, isFullscreen, showSnackbar, snap, position, figures }, ref) => {
    const [multimediaWatched, setMultimediaWatched] = useState(() => figures.map(() => false));
    const playerRef = useRef(null);
    const videoClipElRef = useRef(null);
    const isFullscreenRef = useRef();
    const mouseHoverConfigRef = useRef({ ...initMouseHoverConfig });
    const eventHandlersRef = useRef({});

    const errorConfigRef = useRef({
      delay: 3000,
      delayId: undefined,
    });

    const multimediaClipRef = useRef({
      hasStarted: false,
      loading: undefined,
      muted: clipsConfig?.autoplay,
      paused: true,
      timeToLeave: 0,
      error: false,
    });

    const {
      handleTouch,
      onSrcChanged,
      onWaiting,
      onPlay,
      onPlaying,
      onPause,
      onVolumeChange,
      onTimeUpdate,
      onError,
      onMute,
      onClickVideo,
    } = getClipMethodsForDesktop({
      multimediaClipRef,
      playerRef,
      isFullscreenRef,
      mouseHoverConfigRef,
      errorConfigRef,
      clipsConfig,
      isCurrentClips,
      showSnackbar,
      multimediaWatched,
      setMultimediaWatched,
      position,
      snap,
    });

    useClipsEffects({
      clipsConfig,
      videoClipElRef,
      multimediaClipRef,
      isFullscreenRef,
      playerRef,
      snap,
      isCurrentClips,
      isFullscreen,
      onPause,
      isPreviousClips,
      position,
      mouseHoverConfigRef,
      eventHandlersRef,
    });

    const clipHandler = getClickHandler({ multimediaClipRef, onClickVideo });

    useImperativeHandle(
      ref,
      () => ({
        clipHandler,
        handleTouch,
        onMute,
      }),
      [clipHandler, handleTouch, onMute],
    );

    return (
      <ClipVideo
        ref={playerRef}
        id={CLIP_VIDEO_ID}
        currentVideo={snap.currentClip}
        autoplay={clipsConfig?.autoplay}
        deviceType={DEVICE_TYPE.MOBILE}
        onError={onError}
        onPause={onPause}
        onPlay={onPlay}
        onPlaying={onPlaying}
        onSrcChanged={onSrcChanged}
        onTimeUpdate={onTimeUpdate}
        onVolumeChange={onVolumeChange}
        onWaiting={onWaiting}
        withEvents={isCurrentClips}
        hide
      />
    );
  },
);

ClipsWorkerComponent.propTypes = {
  clipsConfig: shape({}),
  isCurrentClips: bool,
  isPreviousClips: bool,
  isFullscreen: bool,
  showSnackbar: func,
  snap: shape({}),
  position: shape({}),
  figures: arrayOf(shape({})),
};

module.exports = {
  ClipsWorkerComponent,
  getClipMethodsForDesktop,
  getClickHandler,
};
