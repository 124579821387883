/* eslint-disable jsx-a11y/media-has-caption */
const React = require('react');
const isEmpty = require('lodash/isEmpty');

const { forwardRef, useEffect, useMemo, useRef } = React;
const { func, number, shape, string, bool } = require('prop-types');
const { CLIPS_EVENTS } = require('../constants');

const READY_STATE = {
  HAVE_NOTHING: 0,
  HAVE_METADATA: 1,
  HAVE_CURRENT_DATA: 2,
  HAVE_FUTURE_DATA: 3,
  HAVE_ENOUGH_DATA: 4,
};

/* istanbul ignore next */
const bindEvents = (player, options, eventsVideo) => {
  Object.values(CLIPS_EVENTS).forEach(({ eventName }) => {
    const event = eventsVideo[eventName];
    if (typeof event === 'function') {
      player?.on(eventName, (...args) => {
        event(player, options, ...args);
      });
    }
  });
};

const VideoJS = forwardRef((props, playerRef) => {
  const {
    classNameWrapper,
    dataTestid: dataTestIdContainer,
    delay,
    eventsVideo,
    id,
    onPlayReady,
    options,
    withEvents,
  } = props;
  const videoRef = useRef(null);
  const initOnce = useRef(false);

  useEffect(() => {
    let player = playerRef.current;
    const isPlayReadyValid = typeof onPlayReady === 'function';

    if (!isEmpty(options)) {
      const videoElement = videoRef.current;
      const playerReady = () => {
        if (isPlayReadyValid) {
          onPlayReady(player, options, true);
        }
      };

      if (!player) {
        // Make sure Video.js player is only initialized once
        if (!videoElement) {
          return;
        }
        // eslint-disable-next-line no-undef
        player = videojs(videoElement, options, () => {
          playerReady();
          initOnce.current = true;
        });

        playerRef.current = player;
      }

      if (initOnce.current && player) {
        setTimeout(() => {
          playerReady();
        }, delay);
      }
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (player) {
        player?.off();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    if (typeof player?.on === 'function' && withEvents) {
      bindEvents(player, options, eventsVideo);
    } else if (player) {
      player?.pause();
      player?.off();
    }
  }, [eventsVideo, options, playerRef, withEvents]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player?.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const videoProps = useMemo(() => {
    const { autoplay, className, controls, dataTestid, loop, muted, playsinline, preload } = options;

    return {
      'data-testid': dataTestid,
      autoPlay: autoplay,
      className,
      controls,
      loop,
      muted,
      playsInline: playsinline,
      preload,
    };
  }, [options]);

  return (
    <div id={id} className={classNameWrapper} data-testid={dataTestIdContainer}>
      <video ref={videoRef} {...videoProps} />
    </div>
  );
});

VideoJS.propTypes = {
  classNameWrapper: string,
  dataTestid: string.isRequired,
  delay: number,
  eventsVideo: shape({}),
  id: string.isRequired,
  onPlayReady: func.isRequired,
  options: shape({
    autoplay: bool,
    className: string,
    controls: bool,
    currentVideo: shape({}),
    dataTestid: string,
    loadingSpinner: bool,
    loop: bool,
    muted: bool,
    playsinline: bool,
    preload: string,
    preloadVideo: shape({}),
  }).isRequired,
  withEvents: bool,
};

VideoJS.defaultProps = {
  classNameWrapper: '',
  delay: 0,
  eventsVideo: {},
  withEvents: true,
};

VideoJS.displayName = 'VideoJS';

module.exports = VideoJS;
exports = module.exports;
exports.READY_STATE = READY_STATE;
